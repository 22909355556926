import {
  Country,
  LOCAL_STORAGE_COUNTRY_KEY,
  LOCAL_STORAGE_LOCALE_KEY,
} from "app/context/country.context";
import {
  ar,
  de,
  enGB,
  es,
  fr,
  hi,
  it,
  ja,
  nb,
  nl,
  pl,
  ptBR,
  pt,
  ro,
  ru,
  zhCN,
  zhTW,
  sv,
} from "date-fns/locale";
import { Locale } from "date-fns";

const dateFnsLocales = {
  ar,
  de,
  en: enGB,
  es,
  fr,
  hi,
  it,
  ja,
  nb,
  nl,
  pl,
  "pt-BR": ptBR,
  pt,
  ro,
  ru,
  se: sv,
  "zh-CN": zhCN,
  "zh-TW": zhTW,
};

export const getDateFnsLocale = () => {
  return enGB;
};

export const getNextLocale = () => {
  return "en";
};

const internalGetNavigatorLanguage = (checkIpCountry = true): string => {
  let language: string = "en_GB";
  
  return language;
};

export const getNavigatorLanguage = (): string => internalGetNavigatorLanguage(true);
export const getNavigatorLanguageForSignUp = (): string => internalGetNavigatorLanguage(false);