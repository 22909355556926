import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import userReducer from "./slices/user";
import playerReducer from "./slices/player";
import radioReducer from "./slices/radio";
import appVersionReducer from "./slices/app-version";

const STORAGE_KEY = "state";

export function makeStore() {
  const savedState =
    typeof window !== "undefined" && window.localStorage.getItem(STORAGE_KEY);
  const preloadedState = savedState ? JSON.parse(savedState) : {};
  return configureStore({
    reducer: {
      user: userReducer,
      player: playerReducer,
      radio: radioReducer,
      appVersion: appVersionReducer,
    },
    preloadedState,
  });
}

const store = makeStore();
store.subscribe(() =>
  window.localStorage.setItem(STORAGE_KEY, JSON.stringify(store.getState()))
);
export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export default store;
