import React from "react";
interface IconProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
}
const Home: React.FC<IconProps> = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 19 32" fill="none" {...props}>
      <path
        d="M7.01 21.658h4.886M9.453 25.59H2.967a1.6 1.6 0 01-1.6-1.6v-9.582a1.6 1.6 0 01.53-1.189l6.485-5.844a1.6 1.6 0 012.142 0l6.486 5.844a1.6 1.6 0 01.528 1.189v9.582a1.6 1.6 0 01-1.6 1.6H9.454z"
        stroke="#222"
        strokeWidth={1.956}
        strokeLinecap="round"
      />
    </svg>
  );
}

const MemoHome = React.memo(Home);
export default MemoHome;
