import MemoLoader from "app/components/icons/Loader";
import { useStickyState } from "app/hooks/sticky-state.hook";
import { userActions } from "app/store/slices/user";
import { useAppDispatch } from "app/store/store";
import { useRouter } from "next/router";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useGetPillowV2Country } from "services-hooks/hooks";
import { getPillowCategoryPath } from "services-hooks/services";

export const LOCAL_STORAGE_COUNTRY_KEY = "countryCode-v2";
export const LOCAL_STORAGE_LOCALE_KEY = "locale";
export const LOCAL_STORAGE_LOCALE_OVERRIDE_KEY = "locale-override";

interface CountryContextValue {
  country: Country;
  setCountry: (country: Country) => void;
  localeOverride: string | null;
  setLocaleOverride: (localeOverride: string | null) => void;
}
export interface Country {
  countryCode: string;
  name: string;
  logo: string;
  permalink: string;
  ip?: string;
}

const CountryContext = createContext<CountryContextValue | null>(null);

export const CountryContextProvider: React.FC = ({ children }) => {
  const setCountry = () => {};
  const setLocaleOverride = () => {};
  const localeOverride = null;

  const {
    data: ipBasedCountryData,
    isLoading,
    isFetched: isIpBasedCounryFetched,
    refetch,
  } = useGetPillowV2Country({ enabled: true, retry: 1 });
  const ipBasedCountryCode = useMemo(() => ipBasedCountryData?.data.data.country?.toLowerCase() || "gb", [ipBasedCountryData])

  const country = useMemo(() => {
    return { countryCode: ipBasedCountryCode, name: "en", logo: "", permalink: "" };
  }, [ipBasedCountryCode]);

  return (
    <>
      {isLoading || !country ? (
        <div className="loader fullpage">
          <MemoLoader />
        </div>
      ) : (
        <CountryContext.Provider
          value={{ country, setCountry, setLocaleOverride, localeOverride }}
        >
          {children}
        </CountryContext.Provider>
      )}
    </>
  );
};

export const useCountryContext = (): CountryContextValue => {
  const value = useContext(CountryContext);
  if (value === null)
    throw new Error(
      "Make sure that you use your hook inside of CountryContext"
    );
  return value;
};
