import React, { ReactNode, useMemo } from "react";
import Logo from "../icons/Logo";
import styles from "./header.module.scss";
import Link from "next/link";
import MemoHome from "../icons/Home";
import MemoFavorite from "../icons/Favorite";
import clsx from "clsx";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useTranslate } from "app/hooks/lang";
import { useFeaturesContext } from "app/context/features.context";
import MemoSearch from "../icons/Search";

const LazyPlayer = dynamic(
  () => import("app/components/station-player/player-wrapper.component"),
  { ssr: false }
);

const LazyPodcastPlayer = dynamic(
  () =>
    import("app/components/podcast-player/podcast-player-wrapper.component"),
  { ssr: false }
);

const isActiveLink = (href: string, currentPathname: string): boolean => {
  const rawHref = href.replace(/\?(.)+$/g, "");
  if (rawHref === "/") {
    return rawHref === currentPathname;
  }
  return currentPathname.startsWith(rawHref);
};

export const Header: React.FC = () => {
  const router = useRouter();
  const { push } = useRouter();
  const t = useTranslate();
  const onSearchCallback = (query: string) => {
    push(`/search?query=${query}`);
  };
  const { downloadEnabled, demoVersion } = useFeaturesContext();
  const links: { name: string; href: string; icon: ReactNode }[] =
    useMemo(() => {
      return [
        {
          name: t("header.menu.home"),
          href: "/",
          icon: <MemoHome className={clsx(styles.menu_item_icon, styles.icon_home)} />,
        },
        {
          name: t("header.menu.favorites"),
          href: "/favorites?back=true",
          icon: <MemoFavorite className={clsx(styles.menu_item_icon, styles.icon_favs)} />,
        },
      ];
    }, []);
  return (
    <>
      <div className={styles.header}>
        <div className={styles.inner_wrapper} data-navigate-row>
          <div className={styles.logo_wrapper}>
            <Link href="/" passHref>
              <a>
                <Logo className={clsx(styles.logo)} />
              </a>
            </Link>
          </div>
          <div className={styles.player_wrapper}>
            <LazyPlayer />
            <LazyPodcastPlayer />
          </div>
          <div className={styles.side}>
            <div className={styles.menu_items}>
              <div className={styles.menu_items_inner}>
                {links.map((link, index) => (
                  <Link
                    key={`header-link-${link}-${index}`}
                    passHref
                    href={link.href}
                    scroll={false}
                  >
                    <a
                      data-navigate-item
                      className={clsx(styles.menu_item, {
                        [styles.active]: isActiveLink(
                          link.href,
                          router.pathname
                        ),
                      })}
                    >
                      <div className={styles.menu_item_icon_wrapper}>
                        {link.icon}
                      </div>
                      <span>{link.name}</span>
                    </a>
                  </Link>
                ))}
              </div>
              <Link passHref href={"/search?autofocus=true&back=true"}>
                <a data-navigate-item className={clsx(styles.profile)}>
                  <button className={styles.icon_wrapper}>
                    <MemoSearch className={styles.icon} />
                    <p>Search for a radio</p>
                  </button>
                </a>
              </Link>
            </div>
          </div>
          <div className={styles.search_mobile}>
            <Link passHref href={"/search?autofocus=true&back=true"}>
              <a className={clsx(styles.profile, styles.mobile)}>
                <button className={styles.icon_wrapper}>
                  <MemoSearch className={styles.icon} />
                </button>
              </a>
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.navigation_mobile}>
        <div className={styles.navigation_mobile_inner}>
          {links.map((link, index) => (
            <Link
              key={`header-link-mobile-${link}-${index}`}
              passHref
              href={link.href}
              scroll={false}
            >
              <a data-navigate-item>
                <div
                  className={clsx(styles.navigation_item, {
                    [styles.active]: isActiveLink(link.href, router.pathname),
                  })}
                >
                  {link.icon}
                  <span className={styles.label}>{link.name}</span>
                </div>
              </a>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};
