import { useTranslate } from "app/hooks/lang";
import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import { MouseEvent, useMemo } from "react";
import styles from "./footer.module.scss";

export const Footer: React.FC<{ showFooter: boolean }> = ({
  showFooter = true,
}) => {
  const t = useTranslate();
  const router = useRouter();
  const isHome = useMemo(() => {
    return router.pathname === "/";
  }, [router.pathname]);

  const show = useMemo(() => isHome && showFooter, [isHome, showFooter])

  return (
    <footer
      className={clsx(styles.footer, {
        [styles.no_background]: !show,
      })}
    >
      {show && (
        <div>
          <div className={styles.bottom}>
            <menu>
              <Link href={"/privacy-policy?back=true"} passHref>
                <a>{t("privacy-policy")}</a>
              </Link>
            </menu>
          </div>
        </div>
      )}
    </footer>
  );
};