import { useAppDispatch } from "./../store/store";
import { findCategory } from "app/model/category";
import { selectUserState, userActions } from "app/store/slices/user";
import { useAppSelector } from "app/store/store";
import { Category } from "services-hooks/types";

export const LOCAL_STORAGE_FAVS_LIST_KEY = "favs_list";

export const useFavorites = () => {
  const dispatch = useAppDispatch();
  const { favorites } = useAppSelector(selectUserState);

  const addToFavorites = async (
    category: Category,
    isPlaying: boolean = false
  ) => {
    try {
      console.log("addToFavorites", { category });
      if (typeof window !== "undefined" && window.localStorage) {
        const item = window.localStorage.getItem(LOCAL_STORAGE_FAVS_LIST_KEY);
        const list = item !== null ? JSON.parse(item) : { list: [] };

        list.list.push({
          logo: category.logo,
          name: category.name,
          permalink: category.permalink,
        });

        window.localStorage.setItem(
          LOCAL_STORAGE_FAVS_LIST_KEY,
          JSON.stringify(list)
        );
      }

      dispatch(userActions.loadFavorites());
    } catch (e) {
      console.error(e);
    }
  };

  const removeFromFavorites = async (
    category: Category,
    isPlaying: boolean = false
  ) => {
    try {
      if (typeof window !== "undefined" && window.localStorage) {
        const listString =
          window.localStorage.getItem(LOCAL_STORAGE_FAVS_LIST_KEY) || "";
        const list: { list: Category[] } = JSON.parse(listString) || {
          list: [],
        };

        const foundIndex = list.list.findIndex(
          (item: Category) => item.permalink === category.permalink
        );

        if (foundIndex > -1) {
          list.list.splice(foundIndex, 1);
        }

        window.localStorage.setItem(
          LOCAL_STORAGE_FAVS_LIST_KEY,
          JSON.stringify(list)
        );
      }

      dispatch(userActions.loadFavorites());
    } catch (e) {
      console.error(e);
    }
  };

  const isFavoriteCategory = (category: Category) =>
    !!findCategory(favorites || [], category);

  const toggleFavorite = async (
    category: Category,
    isPlaying: boolean = false
  ) => {
    if (isFavoriteCategory(category)) {
      removeFromFavorites(category, isPlaying);
    } else {
      addToFavorites(category, isPlaying);
    }
  };

  return {
    toggleFavorite,
    isFavoriteCategory,
    loading: false,
    addToFavorites,
    removeFromFavorites,
  };
};
