import { nanoid } from "@reduxjs/toolkit";
import { useFeaturesContext } from "app/context/features.context";
import { useNavigationContext } from "app/context/navigation.context";
import { useEffect, useRef } from "react";
import { useUnmount } from "react-use";
import { WEBOS_BACK } from "./keyboard-navigation.hook";
import { useNavigateBack } from "./navigation.hook";

export const useGoBackHistory = (
  readableId: string,
  enabled: boolean = true
) => {
  const { isOverlayPresent } = useNavigationContext();
  const navigateBack = useNavigateBack();
  useEffect(() => {
    const eventHandler = (event: KeyboardEvent) => {
      if (
        event.key === "Escape" ||
        event.keyCode === WEBOS_BACK ||
        ((window as any)["VK_BACK_SPACE"] &&
          event.keyCode === (window as any)["VK_BACK_SPACE"]) ||
        ((window as any)["VK_BACK"] &&
          event.keyCode === (window as any)["VK_BACK"])
      ) {
        navigateBack();
        event.preventDefault();
        event.stopPropagation();
      }
    };

    if (!isOverlayPresent) {
      document.addEventListener("keydown", eventHandler);
    } else {
      document.removeEventListener("keydown", eventHandler);
    }
    return () => document.removeEventListener("keydown", eventHandler);
  }, [enabled, isOverlayPresent]);
};

export const useGoBackEvent = (
  readableId: string,
  onGoBack: () => void,
  enabled: boolean = true,
  triggerOnArrowNavigationOnly: boolean = false
) => {
  const id = useRef(`${readableId}${nanoid()}`);
  const features = useFeaturesContext();
  const { setIdActive, removeId } = useNavigationContext();
  const shouldTrigger =
    enabled &&
    (triggerOnArrowNavigationOnly ? features.arrowNavigationEnabled : true);

  useEffect(() => {
    setIdActive(id.current, shouldTrigger);
  }, [shouldTrigger]);

  useUnmount(() => {
    removeId(id.current);
  });
  
  useEffect(() => {
    const eventHandler = (event: KeyboardEvent) => {
      if (
        event.key === "Escape" ||
        event.keyCode === WEBOS_BACK ||
        ((window as any)["VK_BACK_SPACE"] &&
          event.keyCode === (window as any)["VK_BACK_SPACE"]) ||
        ((window as any)["VK_BACK"] &&
          event.keyCode === (window as any)["VK_BACK"])
      ) {
        onGoBack();
        event.preventDefault();
        event.stopPropagation();
      }
    };

    if (shouldTrigger) {
      document.addEventListener("keydown", eventHandler);
    } else {
      document.removeEventListener("keydown", eventHandler);
    }

    return () => {
      document.removeEventListener("keydown", eventHandler);
    };
  }, [shouldTrigger, onGoBack]);

  return id.current;
};
