import React from "react";
interface IconProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
}
const Favorite: React.FC<IconProps> = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 19" fill="none" {...props}>
      <path
        d="M21.991 9.712l.01-.008.008-.01c3.017-3.014.886-8.445-3.5-8.445-1.636 0-2.947.865-3.979 1.798-.521.472-1.003.99-1.442 1.474l-.267.296-.266.295-.267-.295-.267-.296a20.902 20.902 0 00-1.442-1.474C9.547 2.114 8.236 1.249 6.6 1.249c-4.462 0-6.467 5.478-3.471 8.473l.009.01.009.008 7.634 7.245a2.578 2.578 0 003.55 0l7.66-7.273z"
        stroke="#fff"
        strokeWidth={1.956}
      />
    </svg>
  );
};

const MemoFavorite = React.memo(Favorite);
export default MemoFavorite;
