import Image from "next/image";
import React from "react";
interface IconProps extends React.HTMLProps<HTMLDivElement> {
  className?: string;
  width?: number;
  height?: number;
}
const Logo: React.FC<IconProps> = ({ width = 1060, height = 214, ...props }) => {
  return (
    <div className={props.className}>
      <Image src={'/allradio.png'} layout='fill' />
    </div>
  );
};

const MemoLogo = React.memo(Logo);
export default MemoLogo;
