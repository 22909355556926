import {
  postPillowSessionV2SessionCreate,
  postPillowSessionV2SessionRefresh,
} from "services-hooks/services";

export const refreshToken = (refreshToken: string) => {
  return postPillowSessionV2SessionRefresh({
    client_id: process.env.NEXT_PUBLIC_CLIENT_ID!,
    client_key: process.env.NEXT_PUBLIC_CLIENT_KEY!,
    refresh_token: refreshToken,
  });
};

export const signIn = (deviceId: string) => {
  return postPillowSessionV2SessionCreate({
    client_id: process.env.NEXT_PUBLIC_CLIENT_ID!,
    client_key: process.env.NEXT_PUBLIC_CLIENT_KEY!,
    device_serial: deviceId,
  });
};
