import { userActions } from "app/store/slices/user";
/**
 * You can modify this file
 *
 * @version 5
 */
import Axios, {
  AxiosRequestConfig,
  AxiosError,
  AxiosResponse,
  AxiosInstance,
} from "axios";
import qs from "qs";
import store from "app/store/store";
import { getNavigatorLanguage } from "app/utils/language";
import { refreshToken as authRefreshToken } from "app/services/auth.service";
const shouldIntercept = (error: AxiosError) => {
  try {
    return (
      error.config.url !== "/Pillow/session/v2/session/refresh" &&
      error.config.url !== "/Pillow/session/v2/session/create" &&
      error.response?.status === 401
    );
  } catch (e) {
    return false;
  }
};

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};
const handleError = (error: AxiosError) => {
  if (error.response) {
    return Promise.reject(
      new RequestError(
        error.response.data,
        error.response.status,
        error.response
      )
    );
  }

  if (error.isAxiosError) {
    return Promise.reject(new RequestError("noInternetConnection"));
  }
  return Promise.reject(error);
};
const attachTokenToRequest = (request: any, token: any) => {
  request.headers["Authorization"] = "Bearer " + token;
};

const baseConfig: AxiosRequestConfig = {
  baseURL: process.env.NEXT_PUBLIC_BASE_API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json-patch+json",
  },
  paramsSerializer: (param) => qs.stringify(param, { indices: false }),
};
let isRefreshing = false;
let failedQueue: any[] = [];

let axiosInstance: AxiosInstance;

function getAxiosInstance(security: Security): AxiosInstance {
  if (!axiosInstance) {
    axiosInstance = Axios.create(baseConfig);

    // Response interceptor
    axiosInstance.interceptors.response.use(
      (async (response: AxiosResponse): Promise<SwaggerResponse<any>> => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        /**
         * Example on response manipulation
         *
         * @example
         *   const swaggerResponse: SwaggerResponse = {
         *     ...response,
         *   };
         *   return swaggerResponse;
         */
        return response;
      }) as any,
      async (error: AxiosError) => {
        const state = store.getState();
        const refreshToken = state.user.session?.refresh_token.key;
        if (!shouldIntercept(error)) {
          return handleError(error);
        }
        if ((error.config as any)._retry || (error.config as any)._queued) {
          return Promise.reject(error);
        }
        const originalRequest = error.config as any;
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest._queued = true;
              attachTokenToRequest(originalRequest, token);
              return axiosInstance.request(originalRequest);
            })
            .catch(() => {
              return handleError(error); // Ignore refresh token request's "err" and return actual "error" for the original request
            });
        } else {
          originalRequest._retry = true;
          isRefreshing = true;
          if (refreshToken) {
            try {
              const refreshTokenResponse = await authRefreshToken(refreshToken);
              const newAccessToken =
                refreshTokenResponse.data.data.access_token.key;
              attachTokenToRequest(originalRequest, newAccessToken);
              store.dispatch(
                userActions.setSession(refreshTokenResponse.data.data)
              );
              processQueue(null, newAccessToken);
              return axiosInstance.request(originalRequest);
            } catch (e) {
              console.log("HANDLED", e);
              processQueue(e, null);
              store.dispatch(userActions.logout());
              return handleError(error);
            } finally {
              isRefreshing = false;
            }
          } else {
            return handleError(error);
          }
        }
      }
    );
  }

  // ًًRequest interceptor
  axiosInstance.interceptors.request.use(
    async (requestConfig) => {
      if (!requestConfig.headers) {
        requestConfig.headers = {};
      }
      const state = store.getState();
      requestConfig.headers["X-PillowLocale"] = getNavigatorLanguage();
      requestConfig.headers["X-Pillow-Serial"] = state.user.deviceId;
      if (state.user.session?.access_token.key) {
        requestConfig.headers.authorization = `Bearer ${state.user.session.access_token.key}`;
      }

      return requestConfig;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  return axiosInstance;
}

class RequestError extends Error {
  constructor(
    public message: string,
    public status?: number,
    public response?: AxiosResponse
  ) {
    super(message);
  }

  isApiException = true;
}

export type Security = any[] | undefined;

export interface SwaggerResponse<R> extends AxiosResponse<R> {}

export { getAxiosInstance, RequestError };
